import React from 'react';
import { Helmet } from "react-helmet"

import { StaticImage } from 'gatsby-plugin-image';
import * as s from './realisations-et-demos.module.scss';

import Nav from '../components/Nav';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import crownFavicon from '../images/crown.png';
import heroImageCut from '../images/hero-websites-design-cut.png'
import Head from '../components/Head';

export default function HomePage() {
    return (
        <div>
            <Head>
                <title>Créations et design de site webs - Websites & Design</title>
            </Head>
            <Nav />
            <Hero
                smallTitle
                color="#f1f49f"
            >
                <h1>Nos réalisations & nos démos</h1>
            </Hero>

            <div id={s.content} className="container">
                <p id={s.explanatory}>
                    Vous trouverez ci-dessous nos réalisations, et notre équipe ayant travaillé individuellement dans différents domaines du web, vous trouverez aussi des références de nos emplois passés.
                    <br/>De plus, nous avons crée quelques démos accessible en ligne, afin de vous présenter quelles possibles réalisations vous obtiendrez en commerçant avec nous.
                </p>


                <div className={s.pastWorkBlock}>
                    <h2>Nos travaux et commandes passés</h2>

                    <div className={`${s.pastWorkList} row`}>
                        <div className={`col-md-4 col-lg-3 ${s.pastWork}`}>
                            <a href="https://feeim.net/" target="_blank">
                                <div className={s.img}>
                                    <StaticImage
                                        src="../images/feeim-screenshot.png"
                                    />
                                </div>
                                <p className={s.pastWork_title}>
                                    FEEIM
                                </p>
                                {/*<p className={s.pastWork_author}>
                                    Par Ilyes
                                </p>*/}

                                <p className={s.pastWork_desc}>
                                    Organisme de formations professionnelles
                                </p>
                            </a>
                        </div>

                        <div className={`col-md-4 col-lg-3 ${s.pastWork}`}>
                            <a href="https://nectar-noir.fr" target="_blank">
                                <div className={s.img}>
                                    <StaticImage
                                        src="../images/nectar-noir-screenshot.png"
                                    />
                                </div>
                                <p className={s.pastWork_title}>
                                    Nectar Noir
                                </p>
                                {/*<p className={s.pastWork_author}>
                                    Par Ilyes
                                </p>*/}

                                <p className={s.pastWork_desc}>
                                    Boutique en ligne de produits de beauté
                                </p>
                            </a>
                        </div>

                        <div className={`col-md-4 col-lg-3 ${s.pastWork}`}>
                            <a href="https://lagazettegersoise.fr" target="_blank">
                                <div className={s.img}>
                                    <StaticImage
                                        src="../images/gazette-gersoise-screenshot.png"
                                    />
                                </div>
                                <p className={s.pastWork_title}>
                                    La Gazette Gersoise
                                </p>
                                {/*<p className={s.pastWork_author}>
                                    Par Ilyes
                                </p>*/}

                                <p className={s.pastWork_desc}>
                                    Journal d'information en continu
                                </p>
                            </a>
                        </div>

                        <div className={`col-md-4 col-lg-3 ${s.pastWork}`}>
                            <a href="http://lfdiegosuarez.com/" target="_blank">
                                <div className={s.img}>
                                    <StaticImage
                                        src="../images/lycee-francais-diego-suarez-screenshot.png"
                                    />
                                </div>
                                <p className={s.pastWork_title}>
                                    Lycée Francais de Diego Suarez
                                </p>
                                {/*<p className={s.pastWork_author}>
                                    Par Ilyes
                                </p>*/}

                                <p className={s.pastWork_desc}>
                                    Le site web du Lycée Français de Diego Suarez à Madagascar
                                </p>
                            </a>
                        </div>

                        <div className={`col-md-4 col-lg-3 ${s.pastWork}`}>
                            <a href="https://www.yogahauteariege.com/" target="_blank">
                                <div className={s.img}>
                                    <StaticImage
                                        src="../images/yoga-haute-ariege-screenshot.png"
                                    />
                                </div>
                                <p className={s.pastWork_title}>
                                    Yoga Haute Ariège
                                </p>
                                {/*<p className={s.pastWork_author}>
                                    Par Ilyes
                                </p>*/}

                                <p className={s.pastWork_desc}>
                                    Cours et Stages de yoga en Ariège
                                </p>
                            </a>
                        </div>
                        
                    </div>
                </div>

                <div className={s.pastWorkBlock}>
                    <h2>Démos de réalisations</h2>

                    <div className={`${s.pastWorkList} row`}>
                    <div className={`col-md-4 col-lg-3 ${s.pastWork}`}>
                            <a href="/demos/demo-hotel" target="_blank">
                                <div className={s.img}>
                                    <StaticImage
                                        src="../images/demo-hotel.png"
                                    />
                                </div>
                                <p className={s.pastWork_title}>
                                    Démo Hotel - Auberge - Lodge
                                </p>
                                {/*<p className={s.pastWork_author}>
                                    Par Ilyes
                                </p>*/}

                                <p className={s.pastWork_desc}>
                                    Démo site web pour un hôtel avec réservation en ligne
                                </p>
                            </a>
                        </div>

                        <div className={`col-md-4 col-lg-3 ${s.pastWork}`}>
                            <a href="/demos/demo-coiffeur" target="_blank">
                                <div className={s.img}>
                                    <StaticImage
                                        src="../images/demo-coiffeur.png"
                                    />
                                </div>
                                <p className={s.pastWork_title}>
                                    Démo Coiffeur
                                </p>
                                {/*<p className={s.pastWork_author}>
                                    Par Ilyes
                                </p>*/}

                                <p className={s.pastWork_desc}>
                                    Démo site web pour un salon de coiffure
                                </p>
                            </a>
                        </div>

                        <div className={`col-md-4 col-lg-3 ${s.pastWork}`}>
                            <a href="/demos/demo-communaute" target="_blank">
                                <div className={s.img}>
                                    <StaticImage
                                        src="../images/demo-communaute.png"
                                    />
                                </div>
                                <p className={s.pastWork_title}>
                                    Démo Communauté en ligne
                                </p>
                                {/*<p className={s.pastWork_author}>
                                    Par Ilyes
                                </p>*/}

                                <p className={s.pastWork_desc}>
                                    Démo site web pour une communauté en ligne. Articles gratuits et payants avec abonnement
                                </p>
                            </a>
                        </div>
                    </div>
                </div>

                <div className={s.pastWorkBlock}>
                    <h2>Références d'emploi du web</h2>

                    <p className={s.pastWorkBlockDesc}>
                        Nos collaborateurs ont travaillé pour ces entreprises
                    </p>

                    <div className={`${s.pastWorkList} row`}>
                        <div className={`col-md-4 col-lg-3 ${s.pastWork}`}>
                            <a href="https://spendesk.com/" target="_blank">
                                <div className={s.img}>
                                    <StaticImage
                                        src="../images/spendesk-screenshot.png"
                                    />
                                </div>
                                <p className={s.pastWork_title}>
                                    Spendesk
                                </p>
                                {/*<p className={s.pastWork_author}>
                                    Par Ilyes
                                </p>*/}

                                <p className={s.pastWork_desc}>
                                    Logiciel de gestion en ligne et cartes de paiement pour entreprise.
                                    Travail en tant que développeur informatique du logiciel.
                                </p>
                            </a>
                        </div>

                        <div className={`col-md-4 col-lg-3 ${s.pastWork}`}>
                            <a href="https://shotgun.live/" target="_blank">
                                <div className={s.img}>
                                    <StaticImage
                                        src="../images/shotgun-screenshot.png"
                                    />
                                </div>
                                <p className={s.pastWork_title}>
                                    Shotgun
                                </p>
                                {/*<p className={s.pastWork_author}>
                                    Par Ilyes
                                </p>*/}

                                <p className={s.pastWork_desc}>
                                    Billetterie en ligne et organisation évènementielle.
                                    Travail en tant que développeur informatique des logiciels.
                                </p>
                            </a>
                        </div>

                        <div className={`col-md-4 col-lg-3 ${s.pastWork}`}>
                            <a href="https://www.pierre-fabre.com/" target="_blank">
                                <div className={s.img}>
                                    <StaticImage
                                        src="../images/pierre-fabre-screenshot.png"
                                    />
                                </div>
                                <p className={s.pastWork_title}>
                                    Pierre Fabre
                                </p>
                                {/*<p className={s.pastWork_author}>
                                    Par Ilyes
                                </p>*/}

                                <p className={s.pastWork_desc}>
                                    Laboratoire pharmaceutique.
                                    Travail en tant que gestionnaire de projet web marketing.
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}